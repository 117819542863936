import React from 'react'
import { graphql } from 'gatsby'

import { H3, Link } from '../components/typography'
import Layout from '../components/layout'

export default ({ data }) => {
    const { email, name, impressum } = data.site.siteMetadata
    return (
        <Layout page>
            <H3>Impressum</H3>
            <p>
                <strong>Angaben gemäß § 5 TMG:</strong>
                <br />
                {name}, {impressum}. E-Mail: {email}.
            </p>

            <p>
                <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong>
                <br />
                {name}
            </p>
            <H3>Haftung für Inhalte</H3>
            <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
                rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
                Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
                konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            </p>
            <H3>Haftung für Links</H3>
            <p>
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
                keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
                Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
                Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
                einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Links umgehend entfernen.
            </p>
            <H3>Urheberrecht</H3>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
                Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
                bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
                Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
                umgehend entfernen.
            </p>
            <H3>Datenschutzerklärung</H3>
            <p>
                Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
                Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
                gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Die Nutzung
                unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
                Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift
                oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
                freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an
                Dritte weitergegeben. Wir weisen darauf hin, dass die Datenübertragung im Internet
                (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
                lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
            </p>
            <H3>Cookie</H3>
            <p>
                Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf
                Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu,
                unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind
                kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
                speichert.
            </p>
            <p>
                Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie
                werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf
                Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies ermöglichen es uns,
                Ihren Browser beim nächsten Besuch wiederzuerkennen. Sie können Ihren Browser so
                einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur
                im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
                ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser
                aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
                eingeschränkt sein.
            </p>
            <H3>Google Analytics</H3>
            <p>
                Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist
                die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA. Google
                Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem
                Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie
                ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser
                Website werden in der Regel an einen Server von Google in den USA übertragen und
                dort gespeichert. Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
                finden Sie in der{' '}
                <Link to="https://support.google.com/analytics/answer/6004245?hl=de">
                    Datenschutzerklärung von Google
                </Link>
                .
            </p>
            <p>
                Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
                Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden
                nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie
                erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
                an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das
                unter dem folgenden Link verfügbare{' '}
                <Link to="https://tools.google.com/dlpage/gaoptout?hl=de">
                    Browser-Plugin herunterladen
                </Link>{' '}
                und installieren.
            </p>
            <H3>Widerspruch Werbe-Mails</H3>
            <p>
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur
                Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
                wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich
                rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa
                durch Spam-E-Mails, vor.
            </p>
            <p>
                Quelle: <Link to="https://www.e-recht24.de">eRecht24</Link>
            </p>
        </Layout>
    )
}

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                name
                impressum
                email
            }
        }
    }
`
